import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store";
import { updateNetworkStatus } from "../appSlice";

const useNetworkStatus = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleOnline = () => {
      dispatch(updateNetworkStatus({ isOnline: true }));
    };

    const handleOffline = () => {
      dispatch(updateNetworkStatus({ isOnline: false }));
      navigate("/disconnect");
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
};

export default useNetworkStatus;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store";

const pathMap: { [key: string]: string } = {
  isCompleted: "/end",
  isTerminated: "/terminate",
  isSecondaryDisconnected: "/disconnect",
};

const useStatusNavigation = () => {
  const firebaseState = useAppSelector((state) => state.firebaseState);

  const navigate = useNavigate();

  useEffect(() => {
    const firebaseStateKeys = Object.keys(firebaseState);
    for (const key of firebaseStateKeys) {
      if (firebaseState[key]) {
        const path = pathMap[key];
        if (path) {
          navigate(path);
          break;
        }
      }
    }
  }, [firebaseState]);
};

export default useStatusNavigation;

import Video, { LocalParticipant } from "twilio-video";
import {
  RecordingServiceOptions,
  RecordingService,
} from "../common/hooks/useRecorder";
import { Twilio } from "../globals/enums";

export class TwilioService implements RecordingService {
  private options: RecordingServiceOptions = {};
  public videoTrack = null;
  public twilioRoom = null;
  private networkLevel;

  handleNetworkQualityChange = (connect) => {
    const localNetworkQuality = connect.localParticipant?.networkQualityLevel;

    if (localNetworkQuality !== undefined) {
      const isBelowThreshold = localNetworkQuality < Twilio.NetworkQualityLevelThreshold;
      const isAboveThreshold = localNetworkQuality >= Twilio.NetworkQualityLevelThreshold;
      const currentAboveThreshold = this.networkLevel >= Twilio.NetworkQualityLevelThreshold;

      if (
        (isBelowThreshold && currentAboveThreshold) ||
        (isAboveThreshold && !currentAboveThreshold)
      ) {
        this.networkLevel = localNetworkQuality;
        return isAboveThreshold;
      }
    }

    return false;
  };

  async connect(options: RecordingServiceOptions) {
    this.options = options;
    try {
      const room = await Video.connect(options.streamingToken, {
        audio: false,
        video: {
          aspectRatio: 16 / 9,
        },
        bandwidthProfile: {
          video: {
            trackSwitchOffMode: "disabled"
          }
        },
        networkQuality: {
          local: Twilio.NetworkQualityLocal,
          remote: Twilio.NetworkQualityRemote
        },
        automaticSubscription: false
      });

      room.on("disconnected", () => {
        this.options.onConnectionFailure();
      });

      room.localParticipant.on("networkQualityLevelChanged", () => {
        const isGoodQuality = this.handleNetworkQualityChange(room);
        options.onNetworkQualityChange(isGoodQuality);
      });

      this.twilioRoom = room;
      console.log("Twilio connected");
    } catch (e) {
      options.onConnectionFailure(e);
    }
  }

  previewElement(videoRef) {
    const localParticipant: LocalParticipant = this.twilioRoom.localParticipant;
    const localVideoTrack = Array.from(localParticipant.videoTracks.values())[0].track;
    if (videoRef.current && localVideoTrack) {
      this.videoTrack = localVideoTrack;
      this.videoTrack.attach(videoRef.current);
      this.videoTrack.on("stopped", this.handleTrackStopped);
    }
  }

  disconnect(): void {
    console.log("Twilio Disconnected");
    if (this.twilioRoom) {
      this.twilioRoom?.disconnect();
      if (this.videoTrack) {
        this.videoTrack?.stop();
      }
    }
  }

  private handleTrackStopped() {
    if (this.twilioRoom) {
      this.twilioRoom.disconnect();
      this.options.onTrackChange();
    }
  }
}

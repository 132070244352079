import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import refresh from "../assets/refresh.svg";

const Error = () => {
  useEffect(() => {
    document.body.style.backgroundColor = "#1e293b";
    return () => {
      document.body.style.backgroundColor = "#fff";
    };
  }, []);

  return (
      <div className="endScreen">
        <div className="imageArea">
          <img src={refresh} alt="Re try icon" />
          <div className="title" style={{ color: "red" }}>
            <FormattedMessage id="tryAgain" />
          </div>
        </div>
        <div className="textArea">
          <FormattedMessage id="errorText" />
          <div className="textArea1">
            <FormattedMessage id="closeSessionAndScanAgain" />
          </div>
        </div>
      </div>
  );
};

export default Error;






import { useState } from "react";
import { Outlet } from "react-router-dom";

const Layout = () => {
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  window
    .matchMedia("(orientation: portrait)")
    .addEventListener("change", (e) => {
      const portrait = e.matches;

      if (portrait) {
        setIsPortrait(true);
      } else {
        setIsPortrait(false);
      }
    });

  return (
    <main className={isPortrait ? "tvpc-m-port" : "tvpc-m-land"}>
      <div className="content">
        <Outlet />
      </div>
    </main>
  );
};

export default Layout;

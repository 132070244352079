import { createSlice } from "@reduxjs/toolkit";

interface Token {
  sessionId: string;
  streamToken: string;
  startsAt: string;
  endsAt: string;
}

const sessionSlice = createSlice({
  name: "app",
  initialState: {
    sessionId: "",
    streamToken: "",
    startsAt: "",
    endsAt: "",
    error: "",
    streaming_provider: "",
  },
  reducers: {
    success: (state, { payload: { sessionId, streamToken, startsAt, endsAt, streaming_provider } }) => {
      return { ...state, sessionId, streamToken, startsAt, endsAt, streaming_provider };
    },
    failure: (state, { payload: { error } }) => {
      return { ...state, error };
    },
  },
});

export const { success, failure } = sessionSlice.actions;
export default sessionSlice.reducer;

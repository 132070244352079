import { createSlice} from "@reduxjs/toolkit";
const firebaseStateSlice = createSlice({
  name: "firebaseState",
  initialState: {
    isTerminated: false,
    isCompleted: false,
    isSecondaryDisconnected: false,
  },
  reducers: {
    updateState: (
      state,
      { payload: { isTerminated, isCompleted, isSecondaryDisconnected } }
    ) => {
      state.isCompleted = isCompleted ?? state.isCompleted;
      state.isTerminated = isTerminated ?? state.isTerminated;
      state.isSecondaryDisconnected =
        isSecondaryDisconnected ?? state.isSecondaryDisconnected;
    },
  },
});
export const { updateState } = firebaseStateSlice.actions;
export default firebaseStateSlice.reducer;

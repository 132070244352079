import { useEffect } from "react";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getDatabase, onValue, ref } from "firebase/database";
import { initializeApp } from "firebase/app";
import { updateState } from "../common/firebaseStateSlice";
import firebaseConfig from "./config";
import { useAppDispatch, useAppSelector } from "../store";
import { handleError } from "../../src/helpers/sentry";

interface SecondaryData {
  [key: string]: {
    status: "active" | "connected";
  };
}

const useFirebase = () => {
  const dispatch = useAppDispatch();
  const { sessionId } = useAppSelector((state) => state.session);

  const isSecondaryDeviceDisconnected = (secondaryDevice: SecondaryData) => {
    if (!secondaryDevice) {
      return false;
    }
    return !Object.values(secondaryDevice).some(
      (item) => item.status === "active" || item.status === "connected"
    );
  };

  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const firebaseAuthentication = getAuth(app);
    const firebaseDatabase = getDatabase(app);

    const getData = async () => {
      try {
        await FirebaseAuth(firebaseAuthentication);
        const getDataRef = ref(firebaseDatabase, `/session/${sessionId}`);
        await new Promise((resolve) => {
          onValue(
            getDataRef,
            (snapshot) => {
              const data = snapshot.val();
              if (data) {
                const sessionData = {
                  isTerminated: data.status === "terminated",
                  isCompleted: data.status === "completed",
                  isSecondaryDisconnected: isSecondaryDeviceDisconnected(
                    data?.streaming_device?.secondary
                  ),
                };
                dispatch(updateState(sessionData));
                resolve(data);
              } else {
                resolve(null);
              }
            },
            (error) => {
              console.log("Error getting data from Firebase:", error);
              resolve(null);
            }
          );
        });
      } catch (error) {
        handleError(error);
        console.log("Firebase not connected", error);
      }
    };

    getData();
  }, [dispatch, sessionId]);
};

const FirebaseAuth = async function auth(data: any) {
  await signInAnonymously(data);
};

export default useFirebase;

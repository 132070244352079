import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const proctorService = createApi({
  reducerPath: "proctorService",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_PROVIEW_API_URL,
    prepareHeaders: (headers: Headers) => {
      headers.set("app-id", process.env.REACT_APP_ID as string);
    },
  }),
  endpoints: (builder) => ({
    sessionConnect: builder.mutation({
      query: (body) => ({
        url: `v1/session/connect`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useSessionConnectMutation } = proctorService;

export default proctorService;

import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { xClientVersion } from "../config/constants";

export const createBaseQuery = (additionalHeaders?: Record<string, string>) =>
    fetchBaseQuery({
        baseUrl: process.env.REACT_APP_PROVIEW_API_URL,
        prepareHeaders: (headers: Headers) => {
            headers.set("app-id", process.env.REACT_APP_ID as string);
            headers.set("secondary-device-token", localStorage.getItem("secondary-device-token") || "");
            headers.set("x-talview-client-version", xClientVersion);

            if (additionalHeaders) {
                Object.entries(additionalHeaders).forEach(([key, value]) => {
                    headers.set(key, value);
                });
            }
        },
    });
 
import { handleError } from "../../helpers/sentry";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { useCreateAlertMutation } from "../../../src/services/alert";
import { actionType } from "../../../src/config/constants";

interface QueueItem {
    event: string,
    data: any
}

type QueueState = {
    requestQueue: {
        items: QueueItem[];
    };
};

const useQueueProcessor = (
    queueSelector: (state: QueueState) => { items: QueueItem[] },
    dequeueAction: any
) => {
    const dispatch = useAppDispatch();
    const queue = useAppSelector(queueSelector);
    const isOnline = useAppSelector((state) => state.app.isOnline);
    const [processedQueueItem, setProcessedQueueItem] = useState(null);

    const [createAlert] = useCreateAlertMutation();

    const uploadAlertAction = async (item: QueueItem) => {
        await createAlert(item.data);
    };

    const processQueueItem = async () => {
        const item = queue.items[0];
        if (item !== processedQueueItem) {
            setProcessedQueueItem(item);
            try {
                if (item.event === actionType.Alert)
                    await uploadAlertAction(item);
            } catch (error) {
                handleError(error);
            }
            dispatch(dequeueAction());
        }
    };

    useEffect(() => {
        const processQueue = async () => {
            const { items } = queue;
            if (isOnline && items.length) {
                await processQueueItem();
            }
        };

        processQueue();
    }, [queue.items, isOnline]);
};

export default useQueueProcessor;

import React, { useEffect } from "react";
import DisconnectImage from "../assets/disconnect.svg";
import { FormattedMessage } from "react-intl";

const Terminated = () => {
    useEffect(() => {
        document.body.style.backgroundColor = "#1e293b";
        return () => {
            document.body.style.backgroundColor = "#fff";
        };
    }, []);

    return (
        <div className="endScreen">
            <div className="imageArea">
                <img src={DisconnectImage} alt="Session terminated icon" />
                <div className="title" style={{ color: "red" }}>
                    <FormattedMessage id="terminated" />
                </div>
            </div>
            <div className="textArea">
                <FormattedMessage id="terminatedText" />
                <div className="textArea1">
                    <FormattedMessage id="closeApplication" />
                </div>
            </div>
        </div>
    );
};

export default Terminated;

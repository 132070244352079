import App from "./App";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.ENVIRONMENT,
    integrations: [new Sentry.BrowserTracing({}), new Sentry.Replay()],
    tracesSampleRate: process.env.ENVIRONMENT === 'local' ? 1.0 : 0.1,
    sampleRate: process.env.ENVIRONMENT === 'local' ? 1.0 : 0.1,
    release: process.env.SENTRY_RELEASE || "proctoring-mobile-client@dev",
  });
}

if (process.env.NODE_ENV === "development") {
  import("./test_config/mocks/browser").then(({ worker }) => {
    worker.start();
  });
}

createRoot(document.getElementById("root")).render(<App />);

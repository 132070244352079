import { useNavigate } from "react-router-dom";
import Page2 from "../assets/page2.png";
import { FormattedMessage } from "react-intl";

const SecondaryInstruction = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate("/tertiary");
  };

  return (
      <>
        <div className="top-row">
          <FormattedMessage id="addPhoneSecondary" />
        </div>
        <div className="mid-row">
          <div className="top-midrow">
            <div className="div1">
              <div className="pagenumber">2</div>
              <div className="runningtext">
                <FormattedMessage id="placePhoneVisible" />
              </div>
            </div>
            <div className="div2 iconHolder">
              <img src={Page2} alt="Side view of person with computer icon" />
            </div>
          </div>
          <div className="btm-midrow">
            <div className="div1">
              <div className="runningtext2">
                <FormattedMessage id="viewDemoVideo" />
              </div>
            </div>
              <div className="div2">
                  <div className="videoHolder">
                      <video
                          aria-label="demo video"
                          tabIndex={1}
                          className="videoPlayer"
                          src="https://cdn.proview.io/assets/secondary_cam.mp4"
                          id="tvp_demo_video"
                          controls
                          disablePictureInPicture
                          controlsList="nodownload"
                      />
                  </div>
              </div>
          </div>
        </div>
        <div className="btm-row">
          <button tabIndex={2} className="btn" onClick={handleNext}>
            <FormattedMessage id="next" />
          </button>
        </div>
      </>
  );
};

export default SecondaryInstruction;

import { MessageFormatElement } from "react-intl";
import enMessages from "./translations/en-US.json";
import frMessages from "./translations/fr-FR.json";
import cnMessages from "./translations/zh-CN.json";
import cyMessages from "./translations/cy-GB.json";
import jaMessages from "./translations/ja-JA.json";
import {AppLocale} from "../globals/enums";
export interface MessagesEntry {
    messages: Record<string, string> | Record<string, MessageFormatElement[]> | undefined;
    locale: string;
}
export const appMessages= [
    { messages: enMessages, locale: AppLocale.English },
    { messages: cnMessages, locale: AppLocale.Chinese },
    { messages: cyMessages, locale: AppLocale.Welsh },
    { messages: frMessages, locale: AppLocale.French },
    { messages: jaMessages, locale: AppLocale.Japanese }
];
export enum AlertName {
  SecondaryDeviceJoined = "Secondary device joined",
  CandidateGoodBandwidth = "Secondary device on good bandwidth",
  CandidateLowBandwidth = "Secondary device on low bandwidth",
}

export const AlertType: Record<AlertName, any> = {
  [AlertName.SecondaryDeviceJoined]: {
    id: 211,
    alertName: AlertName.SecondaryDeviceJoined,
  },
  [AlertName.CandidateGoodBandwidth]: {
    id: 225,
    alertName: AlertName.CandidateGoodBandwidth,
  },
  [AlertName.CandidateLowBandwidth]: {
    id: 224,
    alertName: AlertName.CandidateLowBandwidth,
  },
};

import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseQuery } from "./apiHelpers";

export interface AlertApiPayload {
  alert_type_id: number;
  description: string;
  timestamp: string;
  payload?: {
    file: string;
  };
  keepalive?: boolean;
}

export const alertApi = createApi({
  reducerPath: "alert",
  baseQuery: createBaseQuery(),
  endpoints: (builder) => ({
    createAlert: builder.mutation({
      query: (payload: AlertApiPayload) => {
        const { keepalive, ...apiPayload } = payload;
        return {
          url: "/v1/secondary-device/alert",
          method: "POST",
          keepalive: Boolean(keepalive),
          body: apiPayload
        };
      },
      transformResponse(response: any) {
        return response;
      },
      transformErrorResponse(errorResponse: any) {
        return errorResponse?.data?.message;
      }
    })
  })
});

export const { useCreateAlertMutation } = alertApi;

import {Outlet, useSearchParams} from "react-router-dom";
import React, {useEffect} from "react";
import {AppLocale} from "../globals/enums";
import {appMessages} from "../languages/messages";
import {IntlProvider} from "react-intl";

export const Internationalization = () => {
    const  [searchParams] = useSearchParams();
    const [locale, setLocale] = React.useState<AppLocale>(AppLocale.English);
    useEffect(() => {
        const localeParam = searchParams.get("locale");
        if (localeParam) {
            setLocale(localeParam as AppLocale);
        }
    },  []);
    const { messages } = appMessages.find(
        (localeMessage) => localeMessage.locale === locale
    );
    return <IntlProvider defaultLocale={AppLocale.English} locale={locale} messages={messages}>  <Outlet /></IntlProvider>
}
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import { TwilioService } from "../../secondaryCamera/twilio";
import { LivekitService } from "../../secondaryCamera/livekit";
import { handleError } from "../../helpers/sentry";
import { StreamingProvider } from "../../globals/enums";
import { alertHelper } from "../../helpers/alert-helper";
import { AlertName } from "../../helpers/alert-type";

export interface RecordingServiceOptions {
  streamingToken?: string;
  onConnectionFailure?: (error?: Error) => void;
  onNetworkQualityChange?: (goodBandwidth: boolean) => void;
  onTrackChange?: () => void;
}

export interface RecordingService {
  connect(options: RecordingServiceOptions): void;
  disconnect(): void;
  previewElement(videoRef: React.RefObject<HTMLVideoElement>): void;
}

const useRecorder = (videoRef) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const showDisconnection = useRef(false);
  const { streamToken, startsAt, endsAt, streaming_provider } = useAppSelector(
    (state) => state.session
  );
  const { isSecondaryDisconnected, isTerminated, isCompleted } = useAppSelector(
    (state) => state.firebaseState
  );

  const navigateToPermissionErrorScreen = () => {
    navigate("/permissionError");
  };

  const navigateToDisconnectErrorScreen = () => {
    navigate("/disconnect");
  };

  function getProvider(): RecordingService {
    if (streaming_provider === StreamingProvider.Twilio) {
      return new TwilioService();
    } else if (streaming_provider === StreamingProvider.Livekit) {
      return new LivekitService();
    }
  }

  const recordingService = getProvider();

  async function startStreaming() {
    try {
      await recordingService.connect({
        streamingToken: streamToken,
        onConnectionFailure: (e) => {
          handleError(e);
          if (e?.message === "Permission denied") {
            navigateToPermissionErrorScreen();
            return;
          }
          if (!showDisconnection.current) {
            navigateToDisconnectErrorScreen();
          }
        },
        onNetworkQualityChange: (goodBandwidth) => {
          if (goodBandwidth) {
            alertHelper().raiseAlert({
              alert: AlertName.CandidateGoodBandwidth,
              dispatch: dispatch
            });
          } else {
            alertHelper().raiseAlert({
              alert: AlertName.CandidateLowBandwidth,
              dispatch: dispatch
            });
          }
        },
        onTrackChange: () => {
          navigateToPermissionErrorScreen();
        },
      });
      await recordingService.previewElement(videoRef);
    } catch (e) {
      handleError(e);
      navigateToDisconnectErrorScreen();
    }
  }

  const stopStreaming = () => {
    if (recordingService) {
      showDisconnection.current = true;
      recordingService?.disconnect();
    }
  };

  useEffect(() => {
    startStreaming();
    return () => {
      stopStreaming();
    };
  }, []);

  useEffect(() => {
    if (isSecondaryDisconnected || isCompleted || isTerminated) {
      stopStreaming();
    }
  }, [isSecondaryDisconnected, isCompleted, isTerminated]);

  useEffect(() => {
    if (startsAt && endsAt) {
      const duration = new Date(endsAt).getTime() - new Date().getTime();
      const durationTimeout = setTimeout(() => {
        stopStreaming();
      }, duration);
      return () => {
        clearTimeout(durationTimeout);
      };
    }
  }, [startsAt, endsAt]);
};

export default useRecorder;

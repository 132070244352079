import { createSlice } from "@reduxjs/toolkit";

const queueSlice = createSlice({
    name: "requestQueue",
    initialState: {
        items: []
    },
    reducers: {
        enqueueItem: (state, action) => {
            state.items.push(action.payload);
        },
        dequeueItem: (state, action) => {
            if (state.items.length > 0) {
                state.items.shift();
            }
        }
    }
})

export const { enqueueItem, dequeueItem } = queueSlice.actions

export default queueSlice.reducer;

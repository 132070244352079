import { useEffect } from "react";
import EndImage from "../assets/end.svg";
import { FormattedMessage } from "react-intl";
const SessionEnd = () => {
    useEffect(() => {
        document.body.style.backgroundColor = "#1e293b";
        return () => {
            document.body.style.backgroundColor = "#fff";
        };
    }, []);
    return (
            <div className="endScreen">
                <div className="imageArea">
                    <img src={EndImage} alt="Session complete icon" />
                    <div className="title">
                        <FormattedMessage id="sessionEnded" />
                    </div>
                </div>
                <div className="textArea">
                    <FormattedMessage id="sessionEndedText" />
                    <div className="textArea1">
                        <FormattedMessage id="closeApplication" />
                    </div>
                </div>
            </div>
    );
};
export default SessionEnd;

import React from "react";
import { Provider } from "react-redux";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { store } from "./store";
import ProtectedRoute from "./common/ProtectedRoute";
import PrimaryInstruction from "./secondaryCamera/PrimaryInstruction";
import SecondaryInstruction from "./secondaryCamera/SecondaryInstruction";
import TertiaryInstruction from "./secondaryCamera/TertiaryInstruction";
import LiveStream from "./secondaryCamera/LiveStream";
import SessionEnd from "./end/SessionEnd";
import Terminated from "./end/Terminate";
import Disconnection from "./disconnection/Disconnection";
import PermissionError from "./permission/PermissionError";
import Splash from "./common/Splash";
import "./App.css";
import Layout from "./common/Layout";
import { Internationalization } from "./common/Internationalization";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Internationalization />}>
      <Route element={<Layout />}>
        <Route element={<ProtectedRoute />}>
          <Route path="/primary" element={<PrimaryInstruction />} />
          <Route path="/secondary" element={<SecondaryInstruction />} />
          <Route path="/tertiary" element={<TertiaryInstruction />} />
          <Route path="/live_stream" element={<LiveStream />} />
          <Route path="/end" element={<SessionEnd />} />
          <Route path="/terminate" element={<Terminated />} />
          <Route path="/permissionError" element={<PermissionError />} />
        </Route>
        <Route path="/end" element={<SessionEnd />} />
        <Route path="/terminate" element={<Terminated />} />
        <Route path="/disconnect" element={<Disconnection />} />
        <Route path="/" element={<Splash />} />
      </Route>
    </Route>
  )
);

const App = () => {
  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
};

export default App;

/**
 * Environment for x client version.
 * @memberof EnvironmentVariable X_TALVIEW_CLIENT_VERSION
 */
export const xClientVersion: string =
  process.env.REACT_APP_X_TALVIEW_CLIENT_VERSION || "0.0.0";

export enum actionType {
  Alert = "alert",
}

/**
 * WebSocket URL for Livekit server.
 * @memberof EnvironmentVariable REACT_APP_LIVEKIT_WSURL
 */
export const livekitWSUrl = process.env.REACT_APP_LIVEKIT_WSURL;

import React, { useEffect } from "react";
import PermissionIcon from "../assets/permission.png";
import { FormattedMessage } from "react-intl";

const PermissionError = () => {
  useEffect(() => {
    document.body.style.backgroundColor = "#1e293b";
    return () => {
      document.body.style.backgroundColor = "#fff";
    };
  }, []);

  return (
      <div className="errorScreen">
        <div className="imageArea">
          <img src={PermissionIcon} alt="Permission denied icon" />
          <div className="title" style={{ color: "red" }}>
            <FormattedMessage id="noPermission" />
          </div>
          <div className="textArea" style={{ marginTop: "15px" }}>
            <FormattedMessage id="permissionErrorMessage" />
          </div>
        </div>
        <div className="errortext">
          <div className="textArea landarea1">
            <FormattedMessage id="stepsToChangePermission" />
          </div>
          <div className="scrollable" id="scorer">
            <ol>
              <li>
                <FormattedMessage id="step1" />
              </li>
              <li>
                <FormattedMessage id="step2" />
              </li>
              <li>
                <FormattedMessage id="step3" />
              </li>
              <li>
                <FormattedMessage id="step4" />
                <br />
                <FormattedMessage id="step4Additional" />
              </li>
              <li>
                <FormattedMessage id="step5" />
                <br />
                <ul>
                  <li>
                    <FormattedMessage id="step5Option1" />
                  </li>
                  <li>
                    <FormattedMessage id="step5Option2" />
                  </li>
                </ul>
              </li>
            </ol>
          </div>
          <div className="textArea landarea2">
            <FormattedMessage id="completionMessage" />
          </div>
        </div>
      </div>
  );
};

export default PermissionError;

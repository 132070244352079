export enum AppLocale {
  English = "en-US",
  French = "fr-FR",
  Japanese = "ja-JA",
  Welsh = "cy-GB",
  Chinese = "zh-CN",
}

export enum StreamingProvider {
	Twilio = "twilio",
	Livekit = "livekit",
}

export enum Twilio {
  NetworkQualityLevelThreshold = 3,
  NetworkQualityLocal = 1,
  NetworkQualityRemote = 0
}

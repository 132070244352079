import { Navigate, Outlet } from "react-router-dom";
import { RootState, useAppSelector } from "../store";
import useNetworkStatus from "./hooks/useNetworkStatus";
import useFirebase from "../firebase/useFirebase";
import useStatusNavigation from "./hooks/useStatusNavigation";
import { dequeueItem } from "./queueSlice";
import useQueueProcessor from "./hooks/useQueueProcessor";

const ProtectedRoute = () => {
  const { sessionId } = useAppSelector((state) => state.session);
  const queueSelector = (state: RootState) => state.requestQueue;

  if (!sessionId) {
    return <Navigate to="/disconnect" replace={true} />;
  }

  useNetworkStatus();
  useFirebase();
  useStatusNavigation();
  useQueueProcessor(queueSelector, dequeueItem);

  return <Outlet />;
};

export default ProtectedRoute;
